import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from 'services/UserService'

export const initialState = {
    status: 'idle', // idle, | "loading", | "succeeded", | "failed"
    error: null,
    users: [],
    pageNumber: 1,
    totalPage: 1,
    pageSize: 10
}

export const getUsers = createAsyncThunk("users", async (data, { rejectWithValue }) => {
    const { pageNumber, pageSize, query } = data
    try {
        const student_list = await UserService.UserList({ pageNumber, pageSize, query })
        return {
            users: student_list?.data?.user,
            totalPage: student_list?.data?.total_page
        }
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        changeStatus: (state, _) => {
            state.status = "idle"
        },
        changePageNumber: (state, action) => {
            state.status = "idle";
            state.pageNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state, _) => {
                state.status = "loading"
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.users = action.payload?.users
                state.totalPage = action.payload?.totalPage
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getUsersList = (state) => state.user.users
export const getUsersStatus = (state) => state.user.status
export const getUserError = (state) => state.user.error
export const getPageNumber = (state) => state.user.pageNumber
export const getPageSize = (state) => state.user.pageSize
export const getTotalPage = (state) => state.user.totalPage

export const {
    changeStatus, changePageNumber
} = userSlice.actions

export default userSlice.reducer