import { users } from 'utils/api_urls'
import Request from 'utils/request'

const UserService = {}

UserService.UserList = function ({ pageNumber, pageSize, query }) {
	return Request.postRequest(`${users}/list?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}

UserService.UserGetOne = function (id) {
	return Request.getRequest(`${users}/get?id=${id}`)
}

UserService.UserCreate = function (data) {
	return Request.postRequest(`${users}/signup`, data)
}

UserService.UserDelete = function (data) {
	return Request.deleteRequest(`${users}/delete?id=${data}`)
}

export default UserService