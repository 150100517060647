export const host = process.env.REACT_APP_BASE_URL

export const teacher_signin = 'teachers/signin'
export const refresh_token = "refresh/token"
export const auth_signin = "auth/signin"

export const message = "/message"
export const users = "/auth"
export const courses = "/courses"
export const groups = "/groups"
export const payment = "/payment"