import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MessageService from 'services/MessageService';

export const initialState = {
	status: 'idle', // idle, | "loading", | "succeeded", | "failed"
	error: null,
	messages: [],
    pageNumber: 1,
    totalPage: 1,
    pageSize: 10
}

export const getMessages = createAsyncThunk("messages", async (data, { rejectWithValue }) => {
    const {pageNumber, pageSize, query} = data
    try{
        const message_list = await MessageService.MessageList({pageNumber, pageSize, query})

        return {
            messages: message_list.data.messages,
            totalPage: message_list.data.total_page
        }
    }catch(err){
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const messageSlice = createSlice({
	name: 'messages',
	initialState,
    reducers: {
        changeStatus: (state, _) => {
			state.status = "idle"
		},
        changePageNumber: (state, action) => {
            state.status = "idle";
            state.pageNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMessages.pending, (state, _)=> {
                state.status = "loading"
            })
            .addCase(getMessages.fulfilled, (state, action) =>{
                state.status = 'succeeded'
                state.messages = action.payload?.messages
                state.totalPage = action.payload?.totalPage
            })
            .addCase(getMessages.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getMessageList = (state) => state.message.messages
export const getMessageStatus = (state) => state.message.status
export const getMessageError = (state) => state.message.error
export const getPageNumber = (state) => state.message.pageNumber
export const getPageSize = (state) => state.message.pageSize
export const getTotalPage = (state) => state.message.totalPage

export const {
	changeStatus, changePageNumber
} = messageSlice.actions

export default messageSlice.reducer