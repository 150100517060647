import { message } from 'utils/api_urls'
import Request from 'utils/request'

const MessageService = {}

MessageService.MessageList = function ({pageNumber, pageSize, query=''}) {
	return Request.postRequest(`${message}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}

MessageService.MessageCreate = function (data) {
	return Request.postRequest(`${message}/create`,data)
}

MessageService.MessageDelete = function (data) {
	return Request.deleteRequest(`${message}/delete?id=${data}`)
}

MessageService.MessageGetOne = function (id) {
	return Request.getRequest(`${message}?id=${id}`)
}

export default MessageService