import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/apps/messages/message-list')),
    },
    
    {
        key: 'apps.messages',
        path: `${APP_PREFIX_PATH}/messages`,
        component: React.lazy(() => import('views/app-views/apps/messages')),
    },
    {
        key: 'apps-messages-addMessage',
        path: `${APP_PREFIX_PATH}/apps/messages/create-message`,
        component: React.lazy(() => import('views/app-views/apps/messages/add-message')),
    },
    // {
    //     key: 'apps.teachers.edit-teacher',
    //     path: `${APP_PREFIX_PATH}/apps/teachers/edit-teacher/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/teachers/edit-teacher')),
    // },
    {
        key: 'apps-messages-messagesList',
        path: `${APP_PREFIX_PATH}/apps/messages/message-list`,
        component: React.lazy(() => import('views/app-views/apps/messages/message-list')),
    },
    // {
    //     key: 'apps.teachers.info-teacher',
    //     path: `${APP_PREFIX_PATH}/apps/teachers/info-teacher/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/teachers/info-teacher')),
    // },
    // {
    //     key: 'apps-teachers-addToGroup',
    //     path: `${APP_PREFIX_PATH}/apps/teachers/add-to-group/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/teachers/add-to-group')),
    // },
    
    {
        key: 'apps.users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/apps/users/user-list')),
    },
    {
        key: 'apps-users-addUser',
        path: `${APP_PREFIX_PATH}/apps/users/add-user`,
        component: React.lazy(() => import('views/app-views/apps/users/add-user')),
    },
    {
        key: 'apps-users-usersList',
        path: `${APP_PREFIX_PATH}/apps/users/user-list`,
        component: React.lazy(() => import('views/app-views/apps/users/user-list')),
    },    
    {
        key: 'apps-gateways',
        path: `${APP_PREFIX_PATH}/apps/getways/gateway-list`,
        component: React.lazy(() => import('views/app-views/apps/getways/getway-list')),
    },
    // {
    //     key: 'apps-groups-addGroup',
    //     path: `${APP_PREFIX_PATH}/apps/groups/group-student/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/groups/add-group')),
    // },   
]