import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import message from './slices/messageSlice'
import user from './slices/userSlice'
import arch from './slices/archiveSlice'
import course from './slices/courseSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        message,
        user,
        course,
        auth,
        arch,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
